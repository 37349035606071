import React, { useState, useEffect, useContext } from "react";
import {
  getIstoricSalariiPontajeUser,
} from "../backend/PontajeService";
import IstoricSalariiPontajTehnicianItem from "./IstoricSalariiPontajTehnicianItem";

const IstoricSalariiPontajTehnician = ({ user }) => {
  const [istoricSalarii, setIstoricSalarii] = useState([]);
  const [pageSize, setPageSize] = useState(30);
  const [pageNumber, setPageNumber] = useState(1);

  const getIstoricSalariiUser = async () => {
    try {
      const result = await getIstoricSalariiPontajeUser(
        user,
        pageSize,
        pageNumber
      );
      setIstoricSalarii(result);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (user) getIstoricSalariiUser();
  }, [pageNumber, pageSize, user]);

  return (
    <div>
      <div
        onScroll={(e) => {
          const bottom =
            e.target.scrollHeight - e.target.scrollTop ===
            e.target.clientHeight;
          if (bottom) {
            setPageNumber(pageNumber + 1);
          }
        }}
        className="tab_pontaje_tehnician"
      >
        {istoricSalarii.map((istoricSalariu, index) => (
          <IstoricSalariiPontajTehnicianItem
            key={index}
            istoricSalariu={istoricSalariu}
          />
        ))}
      </div>
    </div>
  );
};

export default IstoricSalariiPontajTehnician;
