import { doGet, doPost } from "./RESTUtils";

export const updatePontajService = async (id, pontaj) => {
  return await doPost(`/Logics/pontaj/update/${id}`, pontaj);
};

export const getPontajeService = async () => {
  return await doGet(`/Logics/pontaj/getIstoric`);
};

export const getTehnicianPontajeService = async (
  userName,
  pageSize,
  pageNumber
) => {
  return await doGet(
    `/Logics/pontaj/getIstoric/${userName}?pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
};

export const getIstoricSalariiPontajeUser = async (
  userName,
  pageSize,
  pageNumber
) => {
  return await doGet(
    `/Logics/pontaj/getIstoricSalarii/${userName}`
  );
};

export const getPontajeDispecerService = async () => {
  return await doGet(`/Logics/pontaj/get7Days`);
};

export const getRezumatPontajeService = async () => {
  return await doGet(`/Logics/pontaj/getInformatii`);
};

export const getUserRezumatPontajeService = async (userName) => {
  return await doGet(`/Logics/pontaj/getInformatii/${userName}`);
};
export const getRezumatDispecerService = async () => {
  return await doGet(`/Logics/pontaj/getSalarii`);
};
