import React, { useState, useEffect } from "react";
import "../style/Tabs.css";
import PontajTehnician from "./PontajTehnician";
import Validare from "./Validare";
import IstoricSalariiPontajTehnician from "./IstoricSalariiPontajTehnician";
const TabsPontaje = ({ activeTab, currentUserName, setTab }) => {
  const getCurrentTab = (tab) => {
    switch (tab) {
      case "pontajTehnician":
        return "pontajTehnician";
      case "istoricSalariiTehnician":
        return "istoricSalariiTehnician";  
      case "validare":
        return "validare";
      default:
        return "validare";
    }
  };

  const getCurrentScreen = () => {
    switch (activeTab) {
      case "pontajTehnician":
        return <PontajTehnician user={currentUserName} />;
      case "istoricSalariiTehnician":
        return <IstoricSalariiPontajTehnician user={currentUserName} />; 
      case "validare":
        return <Validare />;
      default:
        return <Validare />;
    }
  };

  useEffect(() => {
    setTab(getCurrentTab(activeTab));
  }, [activeTab]);

  return (
    <>
      <div className="tabs_wrapper">
        <div
          onClick={() => setTab("pontajTehnician")}
          onContextMenu={(e) => {e.preventDefault(); setTab("istoricSalariiTehnician");}}
          className={
            activeTab === "pontajTehnician" || activeTab === "istoricSalariiTehnician" ? "tab_active" : "tab_not_active"
          }
        >
          <p
            className={
              activeTab === "pontajTehnician" || activeTab === "istoricSalariiTehnician"
                ? "tab_text_active"
                : "tab_text_not_active"
            }
          >
            Pontaj Tehnician
          </p>
        </div>
        <div
          onClick={() => setTab("validare")}
          className={activeTab === "validare" ? "tab_active" : "tab_not_active"}
        >
          <p
            className={
              activeTab === "validare"
                ? "tab_text_active"
                : "tab_text_not_active"
            }
          >
            Validare
          </p>
        </div>
      </div>
      <div>{getCurrentScreen()}</div>
    </>
  );
};

export default TabsPontaje;
