import React from "react";
import { Row, Col } from "react-bootstrap";
import User from "./User";
import "../style/Setari.css";
const Tehnicieni = ({ tehnicieni }) => {
  return (
    <div className="mt-5 element">
      <h2>
        Tehnicieni
      </h2>
      <Row>
        <Col>
          <h4>Nume</h4>
        </Col>
        <Col>
          <h4>UserName</h4>
        </Col>

        <Col xs={1}>
          <h4>Pontaj</h4>
        </Col>

        <Col>
          <h4>Lei/Ora</h4>
        </Col>
        <Col>
          <h4>Avans</h4>
        </Col>
      </Row>
      {tehnicieni.map((user, index) => (
        <User key={index} user={user} />
      ))}
    </div>
  );
};

export default Tehnicieni;
