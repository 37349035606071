import React, { useState, useEffect, useContext, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { getSetariService, getUsersService } from "../backend/SetariService";
import ButtonComponent from "../components/basic/ButtonComponent";

import { updateSetariService } from "../backend/SetariService";
import Dispeceri from "./Dispeceri";
import Tehnicieni from "./Tehnicieni";
import Select from "react-select";
import { getZoneService, deleteZonaService } from "../backend/LocationService";
import ConfirmActionComponent from "../components/basic/ConfirmActionComponent";
import { RefreshContext } from "../context/RefreshContext";
import "../style/Setari.css";

const Setari = () => {
  const [tehnicieni, setTehnicieni] = useState([]);
  const [dispeceri, setDispeceri] = useState([]);
  const [zone, setZone] = useState([]);
  const [setari, setSetari] = useState();
  const [selectedZona, setSelectedZona] = useState();
  const [deleteButtonPressed, setDeleteButtonPressed] = useState(false);
  const [applications, setApplications] = useState([{ value: "Logics", label: "LG" },
  { value: "PixiaContur", label: "PC" },
  { value: "TehnicStudio", label: "TS" },
  { value: "IL", label: "IL" },
  { value: "LL", label: "LL" },
  { value: "GE", label: "GE" }]);
  const [selectedApplication, setSelectedApplication] = useState();
  const { currentApplication, setApplication } = useContext(RefreshContext);
  const debounceTermenIscirRef = useRef(null);
  const debounceSetariUpdateRef = useRef(null);
  const [error, setError] = useState();

  const getZone = async () => {
    try {
      const result = await getZoneService();
      setZone(
        result.map(zona => {
          return {
            value: zona.id,
            label: zona.nume
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const deleteZona = async () => {
    try {
      const result = await deleteZonaService(selectedZona.value);
      setSelectedZona(null);
      setZone(
        result.map(zona => {
          return {
            value: zona.id,
            label: zona.nume
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getUsers = async () => {
    try {
      const result = await getUsersService();

      setDispeceri(
        result.filter(user => user.roles.find(role => role.name === "dispecer"))
      );
      setTehnicieni(
        result.filter(user =>
          user.roles.find(role => role.name === "tehnician")
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getSetari = async () => {
    try {
      const result = await getSetariService();
      setSetari(result);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getSetari();
    getZone();
    getUsers();
    setSelectedApplication(applications.find(element => element.value === currentApplication));
  }, []);

  const editSetari = async () => {
    try {
      setError(null);
      await updateSetariService(setari.id, setari);
    } catch (err) {
      setError("Eroare la editarea setarilor");
    }
  };

  const chooseApplication = async (application) => {
    setSelectedApplication(application);
    setApplication(application.value);
  }

  const updateSetari = (setariUpdated) => {
    setSetari({ ...setari, [setariUpdated.key]: setariUpdated.value });
  };

  useEffect(() => {
    if (setari) {
      if (debounceSetariUpdateRef.current) {
        clearTimeout(debounceSetariUpdateRef.current);
      }
      debounceSetariUpdateRef.current = setTimeout(() => {
        editSetari();
      }, 700);
    }
    return () => {
      if (debounceSetariUpdateRef.current) {
        clearTimeout(debounceSetariUpdateRef.current);
      }
    }
  }, [setari]);

  return (
    <Row>
      <Col md={12}>
        <Row className={"mb-3"}>
          <div className="headerWrapper">
            <div className="sediuMasini_header">
              <h2>Setari</h2>
            </div>
          </div>
        </Row>
        <Row className="middleScreen">
          <Col>
            {error && <div style={{ display: "flex", justifyContent: "center", color: "red" }}>
              error
            </div>}
            <Row className="mb-2">
              <Col className="element" xs={1}>Lei/Interventie</Col>
              <Col className="element" xs={1}>
                <input
                  className="w-50"
                  value={(setari && setari.leiInterventii) ?? ""}
                  name="leiInterventii"
                  onChange={e =>
                    updateSetari({ key: e.target.name, value: e.target.value })
                  }
                />
              </Col>
              <Col className="element" xs={1}>Lei/Telefon</Col>
              <Col className="element" xs={1}>
                <input
                  className="w-50"
                  value={(setari && setari.leiTelefon) ?? ""}
                  name="leiTelefon"
                  onChange={e =>
                    updateSetari({ key: e.target.name, value: e.target.value })
                  }
                />
              </Col>
              <Col className="element" xs={1}>Zile concediu</Col>
              <Col className="element" xs={1}>
                <input
                  className="w-50"
                  value={(setari && setari.zileConcediu) ?? ""}
                  name="zileConcediu"
                  onChange={e =>
                    updateSetari({ key: e.target.name, value: e.target.value })
                  }
                />
              </Col>


            </Row>

            <Row className="mb-2">
              <Col className="element" xs={1}>Timer T1</Col>
              <Col className="element" xs={1}>
                <input
                  className="w-50"
                  value={(setari && setari.reviziiT1) ?? ""}
                  name="reviziiT1"
                  onChange={e =>
                    updateSetari({ key: e.target.name, value: e.target.value })
                  }
                />
              </Col>
              <Col className="element" xs={1}>Timer T2</Col>
              <Col className="element" xs={1}>
                <input
                  className="w-50"
                  value={(setari && setari.reviziiT2) ?? ""}
                  name="reviziiT2"
                  onChange={e =>
                    updateSetari({ key: e.target.name, value: e.target.value })
                  }
                />
              </Col>
              <Col className="element" xs={1}>Timer T3</Col>
              <Col className="element" xs={1}>
                <input
                  className="w-50"
                  value={(setari && setari.reviziiT3) ?? ""}
                  name="reviziiT3"
                  onChange={e =>
                    updateSetari({ key: e.target.name, value: e.target.value })
                  }
                />
              </Col>


            </Row>

            <Row>
              <Col className="element" xs={1}>Timer Revizie speciala</Col>
              <Col className="element" xs={1}>
                <input
                  className="w-50"
                  value={(setari && setari.reviziiTSpecial) ?? ""}
                  name="reviziiTSpecial"
                  onChange={e =>
                    updateSetari({ key: e.target.name, value: e.target.value })
                  }
                />
              </Col>

              <Col className="element" xs={1}>Termen ISCIR</Col>
              <Col className="element" xs={1}>
                <input
                  className="w-50"
                  value={(setari && setari.termenIscir) ?? ""}
                  name="termenIscir"
                  onChange={e => {
                    const value = e.target.value;
                    setSetari({ ...setari, termenIscir: e.target.value })
                    if (debounceTermenIscirRef.current) {
                      clearTimeout(debounceTermenIscirRef.current);
                    }
                    debounceTermenIscirRef.current = setTimeout(() => {
                      if (value < 10) {
                        updateSetari({ key: e.target.name, value: 10 });
                      } else if (value >= 100) {
                        updateSetari({ key: e.target.name, value: 99 });
                      } else {
                        updateSetari({ key: e.target.name, value: e.target.value });
                      };
                    }, 700);
                  }}
                />
              </Col>
            </Row>

            <Row className="mb-2 mt-2">
              <Col className xs={3}>
                <Select
                  options={zone}
                  placeholder={"Zona"}
                  onChange={value => setSelectedZona(value)}
                  value={selectedZona}
                />
              </Col>
              {selectedZona && (
                <>
                  <Col xs={3}>
                    <ButtonComponent
                      text={"Sterge"}
                      onClick={() => setDeleteButtonPressed(true)}
                      variant="danger"
                    />
                  </Col>
                  <ConfirmActionComponent
                    buttonIsPressed={deleteButtonPressed}
                    yesPressAction={deleteZona}
                    noPressAction={() => setDeleteButtonPressed(false)}
                    text={`Esti sigur ca vrei sa stergi zona ${selectedZona.label} ?`}
                  />
                </>
              )}
              <Col xs={3}>
                <Select
                  options={applications}
                  placeholder={"Application"}
                  onChange={value => chooseApplication(value)}
                  value={selectedApplication}
                />
              </Col>
            </Row>

            <Dispeceri dispeceri={dispeceri} />

            <Tehnicieni tehnicieni={tehnicieni} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Setari;
