import React from "react";
import { Row, Col } from "react-bootstrap";
import UserDispecer from "./UserDispecer";
import "../style/Setari.css";
const Dispeceri = ({ dispeceri }) => {
  return (
    <div className="element">
         <h2>
            Dispeceri
        </h2>
      <Row>
        <Col>
          <h4>Nume</h4>
        </Col>
        <Col>
          <h4>UserName</h4>
        </Col>

        <Col></Col>
      </Row>
      {dispeceri.map((user, index) => (
        <UserDispecer key={index} user={user} />
      ))}
    </div>
  );
};

export default Dispeceri;
