import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import "../style/LucrareItem.css";
import ButtonComponent from "../components/basic/ButtonComponent";
import ModalComponent from "../components/basic/ModalComponent";
import {
  changeFazaLucrareService,
  confirmDiscutieService,
  deleteLucrareAdministrativService,
  updateLucrarePsiService,
  generareLucrarePsiService,
  generareLucrareIscirService,
} from "../backend/LucrareService";
import { getUserName } from "../backend/RESTUtils";
import { UserContext } from "../context/UserContext";
import { showDateFormat, showTimeFormat, arraysMatch } from "../utils/Utils";
import { useHistory } from "react-router-dom";
import ObservatieLucrare from "./observatie/ObservatieLucrare";
import { RefreshContext } from "../context/RefreshContext";
import FinalizareLucrare from "./FinalizareLucrare";
import FinalizareLucrareComenzi from "./FinalizareLucrareComenzi";
import DeDiscutatReminder from "./DeDiscutatReminder";
import { getRomanianDay, showDateFormatFullYear, showDateFormatFullYearXX } from "../utils/Utils";
import ComandaModal from "./ComandaModal";
import ProgramateModal from "./ProgramateModal";
import { database } from "../firebase";
import CreatableSelect from "react-select/creatable";
import { months } from "../utils/Values";
import { mapMonth, getCurrentMonthIndex } from "../utils/Utils";
import { addObservatieLucrareService } from "../backend/ObservatieService";
import TextAreaComponent from "../components/basic/TextAreaComponent";
import ProgramateModalFinalizare from "./ProgramateModalFinalizare";
import { ref, get } from 'firebase/database';
import DeDiscutatReminderCifru from "./DeDiscutatReminderCifru";

const LucrareItem = ({
  lucrare,
  refreshLucrari,
  currentLucrare,
  setCurrentLucrare,
  setTab,
  setRightTabPontaj,
  //folosit pentru a distinge right click din fisa zilnica de right click din celelalte tab-uri
  fromFisaZilnica = false,
}) => {
  const {
    refreshNumere,
    refreshObservatiiLucrare,
    refreshCurrentLucrare,
    currentMonth,
    blocking,
    setBlocking,
  } = useContext(RefreshContext);
  const history = useHistory();
  const todayConst = new Date();
  const [modal, setModal] = useState(false);
  const [modalComanda, setModalComanda] = useState(false);
  const [modalFisaZilnica, setModalFisaZilnica] = useState(false);
  const [modalObservatie, setModalObservatie] = useState(false);
  const [modalFinalizare, setModalFinalizare] = useState(false);
  const [modalDeDiscutatReminderCifru, setModalDeDicutatReminderCifru] = useState(false);
  const [modalProgramate, setModalProgramate] = useState(false);
  const [modalProgramateFinalizare, setModalProgramateFinalizare] = useState(false);
  const { user, isAdmin, isDispecer } = useContext(UserContext);
  const [error, setError] = useState("");
  const [observatie, setObservatie] = useState();
  const [errorObservatie, setErrorObservatie] = useState(false);

  const closeModalComanda = () => {
    setModalComanda(false);
  };

  const openModalComanda = () => {
    setModalComanda(true);
  };

  const closeModal = () => {
    setModal(false);
    setError("");
    setObservatie(null);
    setErrorObservatie(false);
  };

  const openModal = () => {
    setModal(true);
  };

  const closeModalFisaZilnica = () => {
    setModalFisaZilnica(false);
    setError("");
    setObservatie(null);
    setErrorObservatie(false);
  };

  const openModalFisaZilnica = () => {
    setModalFisaZilnica(true);
  };

  const closeModalObservatie = () => {
    setModalObservatie(false);
  };

  const openModalObservatie = () => {
    setModalObservatie(true);
  };

  const closeModalFinalizare = () => {
    setModalFinalizare(false);
    setError("");
    setObservatie(null);
    setErrorObservatie(false);
  };

  const openModalFinalizare = () => {
    setCurrentLucrare(lucrare);
    setModalFinalizare(true);
  };

  const closeModalDeDiscutatReminderCifru = () => {
    setModalDeDicutatReminderCifru(false);
  };

  const closeModalProgramate = () => {
    setModalProgramate(false);
  };

  const openModalProgramate = () => {
    setCurrentLucrare(lucrare);
    setModalProgramate(true);
  };


  const closeModalProgramateFinalizare = () => {
    setModalProgramateFinalizare(false);
  };

  const openModalProgramateFinalizare = () => {
    setCurrentLucrare(lucrare);
    setModalProgramateFinalizare(true);
  };

  const updateLucrarePsi = async (terSt, terHid) => {
    try {
      setBlocking(true);
      await updateLucrarePsiService(lucrare.uid, {
        terSt: terSt,
        terHid: terHid,
      });
      closeModal();
      refreshLucrari();
    } catch (err) {
      console.error(err);
    } finally {
      setBlocking(false);
    }
  };

  const getFazaColor = () => {
    if (
      lucrare.atribuiti &&
      lucrare.confirmati &&
      arraysMatch(lucrare.atribuiti, lucrare.confirmati)
    )
      return "lucrare_item_descriere_faza7";

    if (lucrare.programata === true && lucrare.scadenta != null) {
      const timeOfLucrare = new Date(lucrare.scadenta);
      if (todayConst >= timeOfLucrare) {
        return "background_color_programata_depasire_scadenta";
      } else if (lucrare.faza === 6) {
        return "background_color_programata_activa";
      }
    }
    if (lucrare.discutieId && lucrare.discutieParticipanti.length > 1)
      return "lucrare_item_descriere_discutie";
    else {
      if (
        lucrare.faza < 5 &&
        ((lucrare &&
          lucrare.atribuiti &&
          lucrare.atribuiti.includes(
            user && user.userRoleDTO && user.userRoleDTO.userName.toUpperCase()
          )) ||
          (lucrare && lucrare.dataTermen))
      ) {
        return "lucrare_item_descriere_atribuiti";
      } else {
        switch (lucrare.faza) {
          case 1:
            return "lucrare_item_descriere_faza1";
          case 2:
            return "lucrare_item_descriere_faza2";
          case 3:
            return "lucrare_item_descriere_faza3";
          case 4:
            return "lucrare_item_descriere_faza4";
          case 5:
            return "lucrare_item_descriere_faza5";
          case 6:
            return "lucrare_item_descriere_faza6";
          case 7:
            return "lucrare_item_descriere_faza7";
          case 8: {
            let text = "lucrare_item_descriere_faza7";
            if (lucrare.isInterventie) text = "lucrare_item_descriere_faza8";
            if (lucrare.lucrareType === "oferte")
              text = "backgroudLocatieOferte";
            if (lucrare.lucrareType === "diverse")
              text = "backgroudLocatieDiverse";
            if (lucrare.lucrareType === "revizii")
              text = "backgroudLocatieRevizii";
            if (lucrare.lucrareType === "iscir")
              text = "backgroudLocatieIscir";

            return text;
          }
          default:
            return "lucrare_item_descriere_faza1";
        }
      }
    }
  };

  const getBackgroundColorLocatie = () => {
    switch (lucrare.lucrareType) {
      case "interventii":
        return "backgroudLocatieInterventii";
      case "ascensoare":
        return "backgroudLocatieAscensoare";
      case "revizii":
        return "backgroudLocatieRevizii";
      case "iscir":
        return "backgroudLocatieIscir";
      case "interfoane":
        return "backgroudLocatieInterfoane";
      case "psi":
        return "backgroudLocatiePsi";
      case "altele":
        return "backgroudLocatieAltele";
      case "sediu":
        return "backgroudLocatieSediuMasini";
      case "diverse":
        return "backgroudLocatieDiverse";
      case "oferte":
        return "backgroudLocatieOferte";
      case "comenzi":
        return "backgroudLocatieComenzi";
      case "administrativ":
        return "backgroudLocatieAdministrativ";
      case "notificari":
        return "backgroudLocatieNotificari";
      default:
        return "backgroundLocatieDefault";
    }
  };
  const getTextColoLocatie = () => {
    if (lucrare && lucrare.locatieDTO && lucrare.locatieDTO.temporar) {
      return "lucrareItemLocatieTextColorRed";
    } else {
      return "lucrareItemLocatieTextColorNormal";
    }
  };

  const getTextColorIfLocatieHasRevizie = () => {
    if (lucrare.locatieDTO && lucrare.locatieDTO.fazaRev && lucrare.faza < 7 && ((lucrare.lucrareType === "ascensoare") || (lucrare.lucrareType === "iscir" && lucrare.fazaIscir == 1))) {
      switch (lucrare.locatieDTO.fazaRev) {
        case 2:
          return "lucrareItemLocatieRevizieFaza2";
        case 3:
          return "lucrareItemLocatieRevizieFaza3";
        case 4:
          return "lucrareItemLocatieRevizieFaza4";
      }
    }
  };

  //TODO culoare
  const descriereTextColor = () => {
    if (lucrare.programata === true && lucrare.scadenta != null) {
      const timeOfLucrare = new Date(lucrare.scadenta);
      if (todayConst >= timeOfLucrare) {
        return "descriereLucrareTextWhite";
      }
    }
    if (lucrare.faza === 8) {
      let text = "descriereLucrareTextFaza7";
      if (lucrare.isInterventie) text = "descriereLucrareInterventie";
      if (lucrare.lucrareType === "oferte") text = "";
      if (lucrare.lucrareType === "diverse") text = "";
      if (lucrare.lucrareType === "revizii") text = "";
      if (lucrare.lucrareType === "iscir") text = "descriereLucrareInterventie";

      return text;
    } else {
      if (lucrare.isInterventie) return "descriereLucrareInterventie";
      else {
        if (lucrare.faza === 7) return "descriereLucrareTextFaza7";
      }
    }
  };

  const rightClickLocatie = (e) => {
    e.preventDefault();
    setModalDeDicutatReminderCifru(true);
  };

  const rightClickDescriere = (e) => {
    e.preventDefault();

    // if (lucrare.lucrareType === "iscir" && lucrare.faza === 1) return;
    if (!lucrare.userCitit && lucrare.faza === 7) {
      setTab("observatii");
      setTimeout(() => {
        setModalFinalizare(false);
      }, 2000);
    }

    // if (lucrare.lucrareType === "revizii" && lucrare.faza < 3) return;

    // TODO de verificat ce se intampla atunci cand esti in administrativ si deschizi o programata...
    if (lucrare.programata) {
      if (lucrare.faza === 1) {
        openModalProgramate();
        return;
      }
      // legat de TODO, aici pus conditie in functie de data
      else {
        const timeOfLucrare = new Date(lucrare.scadenta);
        if (todayConst >= timeOfLucrare) {
          openModalProgramateFinalizare();
        }
        return;
      }
    }

    if (
      lucrare.discutieId ||
      lucrare.lucrareType === "administrativ" ||
      lucrare.lucrareType === "notificari"
    ) {
      openModal();
      return;
    }
    //nu deschide modalul cand esti tehnicial si lucarea este in faza mai mare decat 6
    if (
      !(isAdmin() || isDispecer()) &&
      lucrare.faza > 6 &&
      lucrare.lucrareType !== "comenzi"
    )
      return;
    if (lucrare.faza === 8) return;
    //daca faza e 7 si este dispercer/admin, deschide modalul de finalizare si atat return
    //nu mai este nevoie sa deschide celalalt modal
    if ((isAdmin() || isDispecer()) && lucrare.faza === 7 && !fromFisaZilnica) {
      finalizareLucrare();
      return;
    }
    if (fromFisaZilnica && (isAdmin() || isDispecer())) {
      return;
    } else if (fromFisaZilnica) {
      openModalFisaZilnica();
    } else {
      //nu deschide modalul de finalizare/editare pentru dispecer/admin daca faza e fix 5
      if (!(isAdmin() || isDispecer()) && isUserAtLucrare()) return;
      if (
        (isAdmin() || isDispecer()) &&
        lucrare.faza === 5 &&
        lucrare.lucrareType !== "comenzi"
      )
        return;

      setCurrentLucrare(lucrare);
      openModal();
    }
  };

  const rightClickDate = (e) => {
    e.preventDefault();
    setCurrentLucrare(lucrare);
    openModalObservatie();
  };

  const incepeLucrare = async () => {
    try {
      setBlocking(true);
      await changeFazaLucrareService(lucrare.uid, 5);
      if (fromFisaZilnica) {
        closeModalFisaZilnica();
      } else {
        closeModal();
        history.push("/fisaZilnica");
      }
      refreshNumere();
      refreshLucrari();
      refreshCurrentLucrare();
    } catch (err) {
      setError("Participi deja la o lucrare!");
      console.error(err.message);
    } finally {
      setBlocking(false);
    }
  };

  const updateFazaLucrare = async (faza) => {
    try {
      await changeFazaLucrareService(lucrare.uid, faza);
      closeModal();
      refreshNumere();
      refreshLucrari();
    } catch (err) {
      console.error(err);
    }
  };

  const isLastTehnician = () => {
    if (lucrare.faza === 5) {
      return lucrare.users.length === 1 &&
        lucrare.users[0].userName === getUserName()?.toUpperCase()
        ? true
        : false;
    }
  };

  const isUserAtLucrare = () => {
    let check = false;
    lucrare.users.forEach((user) => {
      if (user.userName === getUserName()?.toUpperCase()) check = true;
    });
    return check;
  };

  const realizatLucrare = async () => {
    try {
      setBlocking(true);

      // it was decided to not request observatie mandatory
      if (observatie?.text?.length > 0) {
        await addObservatieLucrareService(lucrare.uid, observatie);
      }

      await changeFazaLucrareService(lucrare.uid, 7);
      if (fromFisaZilnica) {
        closeModalFisaZilnica();
      } else {
        closeModal();
      }
      refreshNumere();
      refreshLucrari();
      refreshCurrentLucrare();
    } catch (err) {
      console.error(err);
    } finally {
      setBlocking(false);
    }
  };

  const partialLucrare = async () => {
    try {
      setBlocking(true);
      if (observatie)
        await addObservatieLucrareService(lucrare.uid, observatie);

      if (isLastTehnician()) {
        await changeFazaLucrareService(lucrare.uid, 6);
      } else await changeFazaLucrareService(lucrare.uid, 5);

      closeModalFisaZilnica();
      refreshNumere();
      refreshLucrari();
    } catch (err) {
      console.error(err);
    } finally {
      setBlocking(false);
    }
  };
  const revenireLucrare = async (faza) => {
    try {
      setBlocking(true);
      if (!observatie || observatie?.text?.length < 2) {
        setErrorObservatie(true);
      } else {
        await addObservatieLucrareService(lucrare.uid, observatie);
        await changeFazaLucrareService(lucrare.uid, faza);
        closeModal();
        refreshNumere();
        refreshLucrari();
        refreshCurrentLucrare();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setBlocking(false);
    }
  };

  const comandaLivrata = async () => {
    try {
      setBlocking(true);
      await changeFazaLucrareService(lucrare.uid, 7);
      refreshLucrari();
      refreshNumere();
      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setBlocking(false);
    }
  };

  const finalizareLucrare = () => {
    openModalFinalizare();
    closeModal();
  };

  const getModalTitleColor = () => {
    if (
      lucrare.discutieId ||
      lucrare.lucrareType === "administrativ" ||
      lucrare.lucrareType === "notificari"
    )
      return "black";

    if ((isAdmin() || isDispecer()) && lucrare.faza === 6) {
      return "#c60800";
    }
  };

  const getModalTitle = () => {
    if (
      lucrare.faza === 1 &&
      (lucrare.lucrareType === "iscir" || lucrare.terHid || lucrare.terSt)
    )
      return "Generare / Mutare";

    if (
      lucrare.discutieId ||
      lucrare.lucrareType === "administrativ" ||
      lucrare.lucrareType === "notificari"
    )
      return "Realizare";

    if (lucrare.lucrareType === "comenzi" && lucrare.faza === 5)
      return "Comanda Livrata";

    if ((isAdmin() || isDispecer()) && lucrare.faza === 6) return "Atentie!";

    if ((isAdmin() || isDispecer()) && lucrare.faza < 6)
      return "Modificare Prioritate";
    if ((isAdmin() || isDispecer()) && lucrare.faza > 6) return "Finalizare";
    if (lucrare.faza === 5 && isUserAtLucrare()) {
      return lucrare.isInterventie
        ? "Incheiere Interventie"
        : "Incheiere Lucrare";
    } else {
      return lucrare.isInterventie ? "Incepe Interventie" : "Incepe Lucrarea";
    }
  };

  const generareLucrarePsi = async () => {
    try {
      setBlocking(true);

      if (lucrare.lucrareType === "iscir") {
        await generareLucrareIscirService(lucrare.uid);
      } else {
        await generareLucrarePsiService(lucrare.uid);
      }
      refreshLucrari();
      refreshNumere();
      closeModal();
    } catch (err) {
      console.error(err);
    } finally {
      setBlocking(false);
    }
  };

  const getWrapperDescriere = () => {
    if (lucrare.observatii) {
      return "lucrare_item_descriere_with_obs";
    } else {
      return "lucrare_item_descriere";
    }
  };

  const confirmDiscutie = async () => {
    try {
      setBlocking(true);
      if (lucrare.discutieId) await confirmDiscutieService(lucrare.discutieId);
      else await deleteLucrareAdministrativService(lucrare.uid);
      refreshCurrentLucrare();
      refreshLucrari();
      refreshNumere();
      closeModal();
    } catch (err) {
      console.error(err);
      if (err?.response?.data?.message) {
        setError(err?.response?.data?.message);
      }
    } finally {
      setBlocking(false);
    }
  };

  const getTextColorForUserParticipant = (participant) => {
    let classUser = "";

    lucrare.users.forEach((user) => {
      if (user.userName === participant) {
        classUser = "lucrare_item_user_participant_curent";
      }
    });

    return classUser;
  };

  const getTextColorForUserDiscutieParticipant = (participant) => {
    let classUser = "";
    if (
      lucrare.discutieConfirmati &&
      lucrare.discutieConfirmati.includes(participant)
    ) {
      classUser = "lucrare_item_user_participant_curent";
    }

    return classUser;
  };

  const getTextColorForUserAtribuiti = (atribuit) => {
    let classUser = "";
    if (lucrare.confirmati && lucrare.confirmati.includes(atribuit)) {
      classUser = "lucrare_item_user_participant_curent";
    }

    return classUser;
  };

  const isShowRegen = () => {
    return (
      lucrare.terSt ||
      lucrare.terHid ||
      lucrare.lucrareType === "revizii" ||
      lucrare.lucrareType === "iscir"
    );
  };

  const addNrTelefon = () => {
    if (isAdmin() || isDispecer()) {
      const itemsRef = ref(database, "logics");
      get(itemsRef).then(snapshot => {
        let items = snapshot.val();
        if (items === null) {
          if (lucrare.partNr) {
            itemsRef.push({
              userName: user.userRoleDTO.userName,
              nr: lucrare.partNr,
            });
          }
        } else {
          for (let item in items) {
            if (items[item].userName === user.userRoleDTO.userName) {
              return;
            } else {
              if (lucrare.partNr) {
                itemsRef.push({
                  userName: user.userRoleDTO.userName,
                  nr: lucrare.partNr,
                });
              }
            }
          }
        }
      });
    }
  };

  const programataScadenta = () => {
    const timeOfLucrare = new Date(lucrare.scadenta);
    if (lucrare.scadenta !== null && todayConst >= timeOfLucrare) {
      return true;
    }
    return false;
  }

  const showNrInterventii = () => {
    return (
      lucrare.isInterventie &&
      lucrare?.locatieDTO?.i0 +
      lucrare?.locatieDTO?.i1 +
      lucrare?.locatieDTO?.i2 +
      lucrare?.locatieDTO?.i3 >
      1
    );
  };
  return (
    <>
      <Row
        className={
          (currentLucrare && currentLucrare.uid === lucrare.uid) ? "selectedLucrare" : (
            (!lucrare.isRead &&
              !fromFisaZilnica &&
              lucrare.lucrareType !== "revizii" &&
              !(lucrare.programata && !programataScadenta()) &&
              !lucrare.discutieId &&
              lucrare.faza !== 8)
            ? "newLucrare"
            : "mb-1")
        }
      >
        <Col
          onContextMenu={(e) => rightClickLocatie(e)}
          className={`lucrare_item_locatie ${getBackgroundColorLocatie()} ${getTextColorIfLocatieHasRevizie()} ${getTextColoLocatie()}`}
          md={2}
          onClick={() => {
            if (setRightTabPontaj) setRightTabPontaj(false);
            setCurrentLucrare(lucrare);
            setTab("locatie");
          }}
        >
          <div style={{textAlign:"center"}}>{lucrare.locatieDTO && lucrare.locatieDTO.locatie}</div>
          {showNrInterventii() && (
            <div className="lucrare_item_locatie_nr_interventii">
              {lucrare.isInterventie &&
                `${lucrare?.locatieDTO?.i0}.${lucrare?.locatieDTO?.i1}.${lucrare?.locatieDTO?.i2}.${lucrare?.locatieDTO?.i3}`}
            </div>
          )}
          {(lucrare && lucrare?.programata != null && lucrare?.programata === true && lucrare?.faza === 6) && (
            <div className="text-center w-100">
              <div className="lucrareItemLocatieTextColorRed">
                {lucrare?.repetitie != null && lucrare?.repetitie === true ? `${showDateFormatFullYearXX(new Date(lucrare?.scadenta))}` : `${showDateFormatFullYear(new Date(lucrare?.scadenta))}`}
              </div>
            </div>
          )}
          {(lucrare && lucrare?.locatieDTO != null && lucrare?.locatieDTO.hasCifru === true && lucrare?.locatieDTO.cifruChange === true) && (
            <div className="text-center w-100">
              <div className="lucrareItemLocatieTextColorRed">
                CIFRU
              </div>
            </div>
          )}
        </Col>
        <Col
          onContextMenu={(e) => rightClickDescriere(e)}
          onClick={() => {
            if (setRightTabPontaj) setRightTabPontaj(false);
            setTab("detalii");
            setCurrentLucrare(lucrare);
          }}
          className={`${getWrapperDescriere()} ${getFazaColor()} ${descriereTextColor()}`}
          md={7}
        >
          {lucrare.observatii ? (
            <div className="lucrare_item_descriere_obs_wrapper">
              <div className="lucrare_item_descriere_obs">
                <div className="text-center w-100">
                  {lucrare.dataTermen && (
                    <div className="descriere_discutie_text_color">
                      {getRomanianDay(new Date(lucrare.dataTermen))} -{" "}
                      {showDateFormatFullYear(new Date(lucrare.dataTermen))}{" "}
                      {showTimeFormat(new Date(lucrare.dataTermen))}
                    </div>
                  )}
                  {lucrare.atribuiti && (
                    <div
                      className={
                        !arraysMatch(lucrare.atribuiti, lucrare.confirmati)
                          ? `lucrare_item_participanti descriere_discutie_text_color`
                          : `lucrare_item_participanti lucrare_item_user_participant_curent`
                      }
                    >
                      Atribuire:{" "}
                      {lucrare.atribuiti.map((atribuit, index) => {
                        return (
                          <div
                            key={index}
                            className={`ml-1 ${getTextColorForUserAtribuiti(
                              atribuit
                            )}`}
                          >{`${atribuit.toUpperCase()}`}</div>
                        );
                      })}
                    </div>
                  )}
                  {lucrare.discutieId && (
                    <div className="descriere_discutie_text_color">
                      {lucrare.discutieDescriere
                        ? ` DD - ${lucrare.discutieDescriere}`
                        : `DD`}
                      <br />
                    </div>
                  )}
                  {lucrare.lucrareType === "iscir" && (
                    <>
                      {lucrare.terIscir && (
                        <div
                          className={
                            new Date(lucrare.terIscir).getMonth() + 1 <
                              getCurrentMonthIndex() &&
                              new Date(lucrare.terIscir).getFullYear() <
                              new Date().getFullYear()
                              ? "descriere_discutie_text_color text-left w-100"
                              : "text-left w-100"
                          }
                        >
                         {`Scadenta: ${showDateFormatFullYear(new Date(lucrare?.terIscir))}`}
                          <br />
                        </div>
                      )}
                    </>
                  )}

                  {lucrare.lucrareType === "psi" && (
                    <>
                      {lucrare.terHid && (
                        <div
                          className={
                            lucrare.psiExp
                              ? "descriere_discutie_text_color text-left w-100"
                              : "text-left w-100"
                          }
                        >
                          {`S: ${mapMonth(lucrare.terHid)} \u00A0\u00A0\ Z: ${lucrare.locatieDTO.zona
                            }`}
                          <br />
                        </div>
                      )}
                      {lucrare.terSt && (
                        <div
                          className={
                            lucrare.psiExp
                              ? "descriere_discutie_text_color text-left w-100"
                              : "text-left w-100"
                          }
                        >
                          {`S: ${mapMonth(lucrare.terSt)} \u00A0\u00A0\ Z: ${lucrare.locatieDTO.zona
                            }`}
                          <br />
                        </div>
                      )}
                    </>
                  )}
                  {lucrare.partNr ? (
                    <div className="lucrare_with_nr_tel">
                      <div className="d-inline">{lucrare.part1}</div>
                      <div
                        onClick={addNrTelefon}
                        className="d-inline nr_telefon"
                      >
                        {lucrare.partNr}
                      </div>
                      <div className="d-inline">{lucrare.part2}</div>
                    </div>
                  ) : (
                    <div style={{whiteSpace: "pre-wrap"}}>{lucrare.descriere} </div>
                  )}
                  {lucrare.lucrareType === "sediu" && lucrare.tipSediu === "ALIMENTARE" && lucrare.faza === 8 && (
                    <div className="black-color">
                      {lucrare.nrKm && lucrare.combustibil
                        ? `${lucrare.nrKm} km, ${lucrare.combustibil} l` : ``}
                      <br />
                    </div>
                  )}
                </div>
              </div>
              <div className="baraRosieObservatii"></div>
            </div>
          ) : (
            <div className="text-center w-100">
              {lucrare.dataTermen && (
                <div className="descriere_discutie_text_color">
                  {getRomanianDay(new Date(lucrare.dataTermen))} -{" "}
                  {showDateFormatFullYear(new Date(lucrare.dataTermen))}{" "}
                  {showTimeFormat(new Date(lucrare.dataTermen))}
                </div>
              )}
              {lucrare.atribuiti && (
                <div
                  className={
                    !arraysMatch(lucrare.atribuiti, lucrare.confirmati)
                      ? `lucrare_item_participanti descriere_discutie_text_color`
                      : `lucrare_item_participanti lucrare_item_user_participant_curent`
                  }
                >
                  Atribuire:{" "}
                  {lucrare.atribuiti.map((atribuit, index) => {
                    return (
                      <div
                        key={index}
                        className={`ml-1 ${getTextColorForUserAtribuiti(
                          atribuit
                        )}`}
                      >{`${atribuit.toUpperCase()}`}</div>
                    );
                  })}
                </div>
              )}
              {lucrare.discutieId && (
                <div className="descriere_discutie_text_color">
                  {lucrare.discutieDescriere
                    ? ` DD - ${lucrare.discutieDescriere}`
                    : `DD`}
                  <br />
                </div>
              )}
              {lucrare.lucrareType === "iscir" && (
                <>
                  {lucrare.terIscir && (
                    <div
                      className={
                        new Date(lucrare.terIscir).getMonth() + 1 <
                          getCurrentMonthIndex() &&
                          new Date(lucrare.terIscir).getFullYear() <
                          new Date().getFullYear()
                          ? "descriere_discutie_text_color text-left w-100"
                          : "text-left w-100"
                      }
                    >
                      {`Scadenta: ${showDateFormatFullYear(new Date(lucrare?.terIscir))}`}

                      <br />
                    </div>
                  )}
                </>
              )}
              {lucrare.lucrareType === "psi" && (
                <>
                  {lucrare.terHid && (
                    <div
                      className={
                        lucrare.psiExp
                          ? "descriere_discutie_text_color text-left w-100"
                          : "text-left w-100"
                      }
                    >
                      {`S: ${mapMonth(lucrare.terHid)} \u00A0\u00A0\ Z: ${lucrare.locatieDTO.zona
                        }`}
                      <br />
                    </div>
                  )}
                  {lucrare.terSt && (
                    <div
                      className={
                        lucrare.psiExp
                          ? "descriere_discutie_text_color text-left w-100"
                          : "text-left w-100"
                      }
                    >
                      {`S: ${mapMonth(lucrare.terSt)} \u00A0\u00A0\ Z: ${lucrare.locatieDTO.zona
                        }`}
                      <br />
                    </div>
                  )}
                </>
              )}
              {lucrare.partNr ? (
                <div className="lucrare_with_nr_tel">
                  <div className="d-inline">{lucrare.part1}</div>
                  <div onClick={addNrTelefon} className="d-inline nr_telefon">
                    {lucrare.partNr}
                  </div>
                  <div className="d-inline">{lucrare.part2}</div>
                </div>
              ) : (
                <div style={{whiteSpace: "pre-wrap"}}>{lucrare.descriere} </div>
              )}
              {lucrare.lucrareType === "sediu" && lucrare.tipSediu === "ALIMENTARE" && lucrare.faza === 8 && (
                <div className="black-color">
                  {lucrare.nrKm && lucrare.combustibil
                    ? `${lucrare.nrKm} km, ${lucrare.combustibil} l` : ``}
                  <br />
                </div>
              )}
            </div>
          )}
        </Col>

        <Col
          md={3}
          className="lucrare_item_data"
          onContextMenu={(e) => rightClickDate(e)}
          onClick={() => {
            if (setRightTabPontaj) setRightTabPontaj(false);
            setCurrentLucrare(lucrare);
            refreshObservatiiLucrare();
            setTab("observatii");
          }}
        >
          <div className="lucrare_item_date_wrapper">
            {lucrare.lucrareType !== "revizii" &&
              !(lucrare.terHid || lucrare.terSt) ? (
              <div className="lucrare_item_date">
                {showDateFormat(
                  lucrare.discutieCreateDate
                    ? new Date(lucrare.discutieCreateDate)
                    : new Date(lucrare.createDate)
                )}{" "}
                {showTimeFormat(
                  lucrare.discutieCreateDate
                    ? new Date(lucrare.discutieCreateDate)
                    : new Date(lucrare.createDate)
                )}
              </div>
            ) : (
              <div className="lucrare_item_date">
                {showDateFormat(
                  lucrare.discutieCreateDate
                    ? new Date(lucrare.discutieCreateDate)
                    : new Date(lucrare.createDate)
                )}
              </div>
            )}
            <div className="ml-2">
              {lucrare.discutieCreator
                ? `${lucrare.discutieCreator.toUpperCase()}`
                : !isShowRegen()
                  ? `${lucrare.createdUser}`
                  : `REGEN`}
            </div>
          </div>

          {lucrare.discutieCreateDate ? (
            <>
              {lucrare.discutieCreateDate && (
                <div className="lucrare_item_participanti">
                  {lucrare.discutieParticipanti && (
                    <div className="ml-2 lucrare_item_participanti">
                      {lucrare.discutieParticipanti.map(
                        (participant, index) => {
                          return (
                            <div
                              key={index}
                              className={`mr-1 ${getTextColorForUserDiscutieParticipant(
                                participant
                              )}`}
                            >{`${participant.toUpperCase()}`}</div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              {lucrare.startDate && (
                <div className="lucrare_item_date_wrapper">
                  <div className="lucrare_item_date">
                    {showDateFormat(new Date(lucrare.startDate))}{" "}
                    {showTimeFormat(new Date(lucrare.startDate))}{" "}
                    {lucrare.participanti && (
                      <div className="ml-2 lucrare_item_participanti">
                        {lucrare.participanti.map((participant, index) => {
                          return (
                            <div
                              key={index}
                              className={`mr-1 ${getTextColorForUserParticipant(
                                participant
                              )}`}
                            >{`${participant}`}</div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
      <ModalComponent
        titleColor={getModalTitleColor()}
        size={"sm"}
        show={modal}
        close={closeModal}
        headingText={getModalTitle()}
        body={
          <>
            {error != null && error !== "" &&
              <h3 style={{ color: "red" }}>{error}</h3>
            }
            {lucrare.lucrareType === "revizii" &&
              lucrare.faza !== 1 &&
              lucrare.faza !== 2 &&
              (isDispecer() || isAdmin()) && (
                <p>La revizii faza nu se poate modifica manual</p>
              )}
            {lucrare.discutieId ? (
              <div className="text-center">
                {lucrare.createdUser.toLowerCase() !== getUserName() ? (
                  <ButtonComponent
                    variant="primary"
                    text="Ok"
                    onClick={confirmDiscutie}
                    disabled={blocking}
                    hidden={error != null && error != ""}
                  />
                ) : (
                  <div className="text-center">
                    {!lucrare.discutieId &&
                      lucrare.atribuiti &&
                      !arraysMatch(lucrare.atribuiti, lucrare.confirmati) ? (
                      <p>Nu a fost incheiata de persoanele atribuite</p>
                    ) : (
                      <ButtonComponent
                        variant="primary"
                        text="Ok"
                        onClick={confirmDiscutie}
                        disabled={blocking}
                        hidden={error != null && error != ""}
                      />
                    )}
                  </div>
                )}
              </div>
            ) : (
              <>
                {(lucrare.lucrareType === "administrativ" ||
                  lucrare.lucrareType === "notificari") && (
                    <div className="ml-1 mb-2 text-center">
                      <ButtonComponent
                        variant="primary"
                        text="OK"
                        onClick={confirmDiscutie}
                        disabled={blocking}
                        hidden={error != null && error != ""}
                      />
                    </div>
                  )}
                {(isAdmin() || isDispecer()) &&
                  // lucrare.lucrareType !== "revizii" &&
                  lucrare.faza >= 1 &&
                  lucrare.faza <= 4 && (
                    <Row className="mb-3">
                      <Col className="lucare_faze_dispecer">
                        {lucrare.lucrareType === "comenzi" && (
                          <div className="mb-3 ml-2">
                            <ButtonComponent
                              variant="primary"
                              disabled={blocking}
                              text="Comanda"
                              onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                                openModalComanda();
                              }}
                            />
                          </div>
                        )}
                        {lucrare.faza === 1 &&
                          (lucrare.lucrareType === "iscir" ||
                            lucrare.terHid ||
                            lucrare.terSt) && (
                            <div className="lucrare_item_muta_scadenta_wrapper">
                              <div className="mb-3">
                                <ButtonComponent
                                  // variant="primary"
                                  disabled={blocking}
                                  text="Generare"
                                  color={"button_generare_psi"}
                                  onClick={generareLucrarePsi}
                                />
                              </div>
                              {lucrare.terHid && (
                                <div className="lucrare_item_muta_scadenta">
                                  <CreatableSelect
                                    placeholder={"Scadenta"}
                                    // styles={customStyles}
                                    formatCreateLabel={() => null || undefined}
                                    noOptionsMessage={() => null}
                                    isClearable
                                    isLoading={false}
                                    isDisabled={false}
                                    onBlurResetsInput={false}
                                    onChange={(value) => {
                                      updateLucrarePsi(null, value.value);
                                    }}
                                    options={months}
                                    value={{
                                      value: lucrare.terHid,
                                      label: mapMonth(lucrare.terHid),
                                    }}
                                  />
                                </div>
                              )}
                              {lucrare.terSt && (
                                <div className="lucrare_item_muta_scadenta">
                                  <CreatableSelect
                                    placeholder={"Scadenta"}
                                    // styles={customStyles}
                                    formatCreateLabel={() => null || undefined}
                                    noOptionsMessage={() => null}
                                    isClearable
                                    isLoading={false}
                                    isDisabled={false}
                                    onBlurResetsInput={false}
                                    onChange={(value) => {
                                      updateLucrarePsi(value.value, null);
                                    }}
                                    options={months}
                                    value={{
                                      value: lucrare.terSt,
                                      label: mapMonth(lucrare.terSt),
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}

                        {!lucrare.atribuiti &&
                          !lucrare.dataTermen &&
                          !currentMonth &&
                          // !(lucrare.terHid || lucrare.terSt) &&
                          !(
                            lucrare.lucrareType === "revizii" &&
                            lucrare.faza > 2
                          ) &&
                          !(
                            lucrare.lucrareType === "iscir" && lucrare.faza <= 1
                          ) && (
                            <>
                              <div
                                className={
                                  currentLucrare && currentLucrare.faza === 4
                                    ? "mb-2 lucrare_dispecer_faza4 selectedFaza"
                                    : "mb-2 lucrare_dispecer_faza4"
                                }
                                onClick={() => updateFazaLucrare(4)}
                              ></div>
                              {lucrare.lucrareType !== "revizii" && (
                                <div
                                  className={
                                    currentLucrare && currentLucrare.faza === 3
                                      ? "mb-2 lucrare_dispecer_faza3 selectedFaza"
                                      : "mb-2 lucrare_dispecer_faza3"
                                  }
                                  onClick={() => updateFazaLucrare(3)}
                                ></div>
                              )}

                              {lucrare.lucrareType !== "revizii" && (
                                <div
                                  className={
                                    currentLucrare && currentLucrare.faza === 2
                                      ? "mb-2 lucrare_dispecer_faza2 selectedFaza"
                                      : "mb-2 lucrare_dispecer_faza2"
                                  }
                                  onClick={() => updateFazaLucrare(2)}
                                ></div>
                              )}
                              {!lucrare.terHid &&
                                !lucrare.terSt &&
                                lucrare.lucrareType != "iscir" &&
                                lucrare.lucrareType !== "revizii" && (
                                  <div
                                    className={
                                      currentLucrare &&
                                        currentLucrare.faza === 1
                                        ? "lucrare_dispecer_faza1 selectedFaza"
                                        : "mb-2 lucrare_dispecer_faza1"
                                    }
                                    onClick={() => updateFazaLucrare(1)}
                                  ></div>
                                )}
                            </>
                          )}
                        {(lucrare.atribuiti || lucrare.dataTermen) &&
                          lucrare.lucrareType !== "administrativ" &&
                          lucrare.lucrareType !== "notificari" && (
                            <p>
                              Nu se poate modifica prioritatea pentru ca este o
                              lucrare atribuita.
                            </p>
                          )}
                      </Col>
                    </Row>
                  )}
                {(isAdmin() || isDispecer()) && lucrare.faza === 6 && (
                  <>
                    <div>
                      <TextAreaComponent
                        className={errorObservatie ? "descriereError" : ""}
                        placeholder="Observatie detalii"
                        value={observatie ? observatie.text : ""}
                        onChange={(e) => {
                          setObservatie({
                            ...observatie,
                            text: e.target.value,
                          });
                          setErrorObservatie(false);
                        }}
                        rows={3}
                        cols={30}
                        autoFocus
                      />
                    </div>
                    <div className="text-center">
                      <div className="mb-2">
                        <ButtonComponent
                          disabled={blocking}
                          variant="primary"
                          text={
                            lucrare.lucrareType === "iscir" &&
                              lucrare.fazaIscir === 1
                              ? "Realizare pregatire"
                              : "Realizare"
                          }
                          onClick={realizatLucrare}
                        />
                      </div>
                      <div>
                        <ButtonComponent
                          disabled={blocking}
                          variant="warning"
                          text="Revenire"
                          onClick={() => revenireLucrare(4)}
                        />
                      </div>
                    </div>
                  </>
                )}

                {!isAdmin() &&
                  !isDispecer() &&
                  lucrare.faza <= 6 &&
                  lucrare.lucrareType !== "notificari" && (
                    <div className="text-center">
                      {error ? (
                        <h3>{error}</h3>
                      ) : (
                        <ButtonComponent
                          variant="success"
                          text="Incepe"
                          onClick={incepeLucrare}
                          disabled={blocking}
                        />
                      )}
                    </div>
                  )}
                {(isAdmin() || isDispecer()) &&
                  lucrare.faza === 5 &&
                  lucrare.lucrareType === "comenzi" && (
                    <div className="text-center">
                      <ButtonComponent
                        variant="primary"
                        text="Livrata"
                        onClick={comandaLivrata}
                        disabled={blocking}
                      />
                    </div>
                  )}
              </>
            )}
          </>
        }
      />

      <ModalComponent
        size="sm"
        show={modalFisaZilnica}
        close={closeModalFisaZilnica}
        headingText={getModalTitle()}
        body={
          <>
            <div className="text-center">
              {isLastTehnician() && (
                <div className="mb-2">
                  <ButtonComponent
                    disabled={blocking}
                    variant="primary"
                    text={
                      lucrare.lucrareType === "iscir" && lucrare.fazaIscir === 1
                        ? "Realizare pregatire"
                        : "Realizare"
                    }
                    onClick={realizatLucrare}
                  />
                </div>
              )}
              {lucrare.faza === 5 && isUserAtLucrare() && (
                <div>
                  <ButtonComponent
                    variant="warning"
                    text="Partial"
                    onClick={partialLucrare}
                    disabled={blocking}
                  />
                </div>
              )}
              {/* faza 4 este cand dispecerul da reverere */}
              {(lucrare.faza === 6 ||
                lucrare.faza === 4 ||
                lucrare.faza === 5) &&
                !isUserAtLucrare() && (
                  <>
                    {error ? (
                      <h3>{error}</h3>
                    ) : (
                      <ButtonComponent
                        variant="success"
                        text="Incepe"
                        onClick={incepeLucrare}
                        disabled={blocking}
                      />
                    )}
                  </>
                )}
            </div>
          </>
        }
      />
      <ModalComponent
        size={"md"}
        show={modalObservatie}
        close={closeModalObservatie}
        headingText={"Observatie"}
        body={
          <ObservatieLucrare
            lucrareId={lucrare.uid}
            closeModalObservatie={closeModalObservatie}
            setTab={setTab}
          />
        }
      />
      <ModalComponent
        size={"md"}
        show={modalFinalizare}
        close={closeModalFinalizare}
        headingText={"Finalizare"}
        body={
          <>
            {!lucrare.userCitit ? (
              <div>Observatii neluate la cunostinta!</div>
            ) : (
              <>
                {lucrare.lucrareType === "comenzi" ? (
                  <FinalizareLucrareComenzi
                    lucrare={lucrare}
                    closeModalFinalizare={closeModalFinalizare}
                    setTab={setTab}
                    refreshLucrari={refreshLucrari}
                  />
                ) : (
                  <FinalizareLucrare
                    lucrare={lucrare}
                    closeModalFinalizare={closeModalFinalizare}
                    setTab={setTab}
                    refreshLucrari={refreshLucrari}
                  />
                )}
              </>
            )}
          </>
        }
      />
      <ModalComponent
        size={"xs"}
        show={modalDeDiscutatReminderCifru}
        close={closeModalDeDiscutatReminderCifru}
        headingText={""}
        body={
          <DeDiscutatReminderCifru
            closeModalDeDiscutatReminder={closeModalDeDiscutatReminderCifru}
            lucrareId={lucrare.uid}
            refreshLucrari={refreshLucrari}
            discutieId={lucrare.discutieId}
            hasLocatieCifru={lucrare?.locatieDTO?.hasCifru ?? false}
            locatieCifru={lucrare?.locatieDTO?.cifru}
            changeLocatieCifru={lucrare?.locatieDTO?.cifruChange ?? false}
            locatieId={lucrare?.locatieDTO?.id}
          />
        }
      />
      <ModalComponent
        size={"xs"}
        show={modalProgramate}
        close={closeModalProgramate}
        headingText={"Programare"}
        body={
          <ProgramateModal
            closeModal={closeModalProgramate}
            currentLucrare={currentLucrare}
            refreshLucrari={refreshLucrari}
            programataImportanta={currentLucrare?.programataImportanta}
          />
        }
      />
      <ModalComponent
        size={"sm"}
        show={modalProgramateFinalizare}
        close={closeModalProgramateFinalizare}
        headingText={"Realizare"}
        body={
          <ProgramateModalFinalizare
            closeModalProgramateFinalizare={closeModalProgramateFinalizare}
            closeModal={closeModal}
            currentLucrare={currentLucrare}
            refreshLucrari={refreshLucrari}
          />
        }
      />
      <ModalComponent
        size={"xs"}
        show={modalComanda}
        close={closeModalComanda}
        headingText={"Comanda"}
        body={
          <ComandaModal
            closeModalComanda={closeModalComanda}
            closeModal={closeModal}
            currentLucrare={currentLucrare}
            refreshLucrari={refreshLucrari}
          />
        }
      />
    </>
  );
};

export default LucrareItem;
