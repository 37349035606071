import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { updateUserFinanceService } from "../backend/SetariService";

const User = ({ user }) => {
  const [setare, setSetare] = useState();
  const userDebounce = useRef(null);

  useEffect(() => {
    setSetare(user);
  }, [user]);

  useEffect(() => {
    if (setare) {
      if (userDebounce.current) {
        clearTimeout(userDebounce.current);
      }
      userDebounce.current = setTimeout(() => {
        editUserFinance();
      }, 700);
      return () => {
        if (userDebounce.current) {
          clearTimeout(userDebounce.current);
        }
      }
    }
  }, [setare]);

  const updateSetare = (updatedSetare) => {
    setSetare({ ...setare, [updatedSetare.name]: updatedSetare.value });
  };

  const editUserFinance = async () => {
    try {
      await updateUserFinanceService(setare.id, {
        leiOra: setare.leiOra,
        avans: setare.avans,
        pontaj: setare.pontaj
      });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Row className="mb-1">
      <Col>{`${user.firstName} ${user.lastName}`}</Col>
      <Col>{user.userName}</Col>
      <Col xs={1}>
        <input
          type="checkbox"
          checked={(setare && setare.pontaj) ?? false}
          name="pontaj"
          onChange={e => updateSetare({ name: e.target.name, value: e.target.checked })}
        />
      </Col>
      <Col>
        <input
          value={(setare && setare.leiOra) ?? ""}
          name="leiOra"
          onChange={e => updateSetare({ name: e.target.name, value: e.target.value })}
        />
      </Col>
      <Col>
        <input
          value={(setare && setare.avans) ?? ""}
          name="avans"
          onChange={e => updateSetare({ name: e.target.name, value: e.target.value })}
        />
      </Col>
    </Row>
  );
};

export default User;
