import React, { useContext, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import logo_text from "../imgs/logo_text.png";
import logo from "../imgs/favicon.png";
import logo_tehnicstudio from "../imgs/logo_tehnicstudio.png";
import logo_pixia from "../imgs/logo_pixia.png";
import new_media_black from "../imgs/new_media_black.png";
import new_media_red from "../imgs/new_media_red.png";
import "../style/LeftDashboard.css";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { getNumbersService } from "../backend/LucrareService";
import ModalComponent from "./basic/ModalComponent";
import Lucrare from "../lucrare/Lucrare";
import { RefreshContext } from "../context/RefreshContext";
import { refreshTimer } from "../utils/Values";
import { Col } from "react-bootstrap";
import { getNewMedia as getNewMediaRest } from "../backend/FileUploadService";
import { refreshNewMediaTimer } from "../utils/Values";

export const schimbaDomeniu = async (domeniu) => {
  LeftDashboard.setCurrentDomeniu(domeniu);
}

const LeftDashboard = ({ domeniu }) => {
  const {
    triggerNumere,
    refreshInterventii,
    setInternet,
    setServerError,
    setCurrentMonth,
    setCurrentUser,
    setIscirFaza1,
    setReviziiFaza1,
    setAdminnistrativProgramate,
    currentDomeniu,
    setCurrentDomeniu,
    isLogicsApp,
    isPixiaConturApp,
    isTehnicStudioApp,
    isILApp,
    isLLApp,
    isGEApp,
    refreshNewMediaContext
  } = useContext(RefreshContext);
  const {
    user
  } = useContext(UserContext);

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { handleLogout, isAdmin, isDispecer } = useContext(UserContext);

  const [administrativ12, setAdministrativ12] = useState(0);
  const [administrativ34, setAdministrativ34] = useState(0);

  const [notificari12, setNotificari12] = useState(0);
  const [notificari34, setNotificari34] = useState(0);

  const [oferte34, setOferte34] = useState(0);
  const [oferte7, setOferte7] = useState(0);

  const [blinkAscensoare, setBlinkAscensoare] = useState(false);
  const [blinkAdminProg, setBlinkAdminProg] = useState(false);
  const [blinkInterfoane, setBlinkInterfoane] = useState(false);
  const [blinkPsi, setBlinkPsi] = useState(false);
  const [blinkAltele, setBlinkAltele] = useState(false);
  const [blinkRevizii, setBlinkRevizii] = useState(false);
  const [blinkIscir, setBlinkIscir] = useState(false);
  const [blinkSediu, setBlinkSediu] = useState(false);
  const [blinkDiverse, setBlinkDiverse] = useState(false);
  const [blinkInterventi, setBlinkInterventii] = useState(false);
  const [blinkComenzi, setBlinkComenzi] = useState(false);
  const [blinkAdministrativ, setBlinkAdministrativ] = useState(false);
  const [blinkNotificari, setBlinkNotificari] = useState(false);
  const [blinkOferte, setBlinkOferte] = useState(false);

  const [ascensoareBlink5, setAscensoareBlink5] = useState(false);
  const [iscirBlink5, setIscirBlink5] = useState(false);
  const [interfoaneBlink5, setInterfoaneBlink5] = useState(false);
  const [psiBlink5, setPsiBlink5] = useState(false);
  const [alteleBlink5, setAlteleBlink5] = useState(false);
  const [sediuBlink5, setSediuBlink5] = useState(false);
  const [diverseBlink5, setDiverseBlink5] = useState(false);
  const [interventiiBlink5, setInterventiiBlink5] = useState(false);
  const [comenziBlink5, setComenziBlink5] = useState(false);
  const [reviziiBlink5, setReviziiBlink5] = useState(false);

  const [isReadAdministrativ, setIsReadAdministrativ] = useState(true);
  const [isReadNotificari, setIsReadNotificari] = useState(true);
  const [isReadInterventii, setIsReadInterventii] = useState(true);
  const [isReadAscensoare, setIsReadAscensoare] = useState(true);
  const [isReadIscir, setIsReadIscir] = useState(true);
  const [isReadInterfoane, setIsReadInterfoane] = useState(true);
  const [isReadPsi, setIsReadPsi] = useState(true);
  const [isReadAltele, setIsReadAltele] = useState(true);
  const [isReadSediu, setIsReadSediu] = useState(true);
  const [isReadDiverse, setIsReadDiverse] = useState(true);
  const [isReadComenzi, setIsReadComenzi] = useState(true);
  const [isReadOferte, setIsReadOferte] = useState(true);

  const [comenzi12, setComenzi12] = useState(0);
  const [comenzi34, setComenzi34] = useState(0);
  const [comenzi56, setComenzi56] = useState(0);
  const [comenzi7, setComenzi7] = useState(0);

  const [interventii34, setInterventii34] = useState(0);
  const [interventii56, setInterventii56] = useState(0);
  const [interventii7, setInterventii7] = useState(0);

  const [ascensoare12, setAscensoare12] = useState(0);
  const [ascensoare34, setAscensoare34] = useState(0);
  const [ascensoare56, setAscensoare56] = useState(0);
  const [ascensoare7, setAscensoare7] = useState(0);

  const [interfoane12, setInterfoane12] = useState(0);
  const [interfoane34, setInterfoane34] = useState(0);
  const [interfoane56, setInterfoane56] = useState(0);
  const [interfoane7, setInterfoane7] = useState(0);

  const [psi2, setPsi2] = useState(0);
  const [psi34, setPsi34] = useState(0);
  const [psi56, setPsi56] = useState(0);
  const [psi7, setPsi7] = useState(0);

  const [altele12, setAltele12] = useState(0);
  const [altele34, setAltele34] = useState(0);
  const [altele56, setAltele56] = useState(0);
  const [altele7, setAltele7] = useState(0);

  const [iscir12, setIscir12] = useState(0);
  const [iscir34, setIscir34] = useState(0);
  const [iscir56, setIscir56] = useState(0);
  const [iscir7, setIscir7] = useState(0);

  const [sediuMasini12, setSediuMasini12] = useState(0);
  const [sediuMasini34, setSediuMasini34] = useState(0);
  const [sediuMasini56, setSediuMasini56] = useState(0);
  const [sediuMasini7, setSediuMasini7] = useState(0);

  const [diverse12, setDiverse12] = useState(0);
  const [diverse34, setDiverse34] = useState(0);
  const [diverse56, setDiverse56] = useState(0);
  const [diverse7, setDiverse7] = useState(0);

  const [revizii34, setRevizii34] = useState(0);
  const [revizii56, setRevizii56] = useState(0);
  const [revizii7, setRevizii7] = useState(0);

  const [dispecerPontajeNr, setDispecerPontajeNr] = useState(0);
  const [tehnicianPontaje, setTehnicianPontaje] = useState(false);

  const [currentLocation, setCurrenctLocation] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [refreshNewMedia, setRefreshNewMedia] = useState(false);
  const [isNewMedia, setIsNewMedia] = useState(false);

  const checkInternet = () => {
    if (navigator && navigator.onLine) {
      setInternet(true);
    } else {
      setInternet(false);
    }
  };

  const getNewMedia = async () => {
    try {
      const newMedia = await getNewMediaRest();
      if (newMedia && newMedia.length > 0 && newMedia.some(lucrare => lucrare.seen == false)) {
        setIsNewMedia(true);
      }
      else {
        setIsNewMedia(false);
      }
    }
    catch (err) {
      setIsNewMedia(false);
    }
  }

  const getNumbers = async () => {
    try {
      const result = await getNumbersService();

      setServerError(false);

      setAscensoareBlink5(result.ascensoareBlink5);
      setInterfoaneBlink5(result.interfoaneBlink5);
      setPsiBlink5(result.psiBlink5);
      setAlteleBlink5(result.alteleBlink5);
      setSediuBlink5(result.sediuBlink5);
      setDiverseBlink5(result.diverseBlink5);
      setInterventiiBlink5(result.interventieBlink5);
      setComenziBlink5(result.comenziBlink5);
      setReviziiBlink5(result.reviziiBlink5);
      setIscirBlink5(result.iscirBlink5);

      setBlinkAscensoare(result.atribuitAscensoare);
      setBlinkInterfoane(result.atribuitInterfoane);
      setBlinkAltele(result.atribuitAltele);
      setBlinkDiverse(result.atribuitDiverse);
      setBlinkSediu(result.atribuitSediu);
      setBlinkPsi(result.atribuitPsi);
      setBlinkInterventii(result.atribuitInterventie);
      setBlinkComenzi(result.atribuitComenzi);
      setBlinkOferte(result.atribuitOferte);
      setBlinkRevizii(result.reviziiBlink4);
      setBlinkAdministrativ(result.atribuitAdm);
      setBlinkAdminProg(result.adminProgBlink);
      setBlinkNotificari(result.atribuitNot);
      setBlinkIscir(result.atribuitIscir);
      setIsReadAdministrativ(result.isReadAdministrativ);
      setIsReadNotificari(result.isReadNotificari);
      setIsReadInterventii(result.isReadInterventii);
      setIsReadAltele(result.isReadAltele);
      setIsReadAscensoare(result.isReadAscensoare);
      setIsReadInterfoane(result.isReadInterfoane);
      setIsReadPsi(result.isReadPsi);
      setIsReadSediu(result.isReadSediu);
      setIsReadDiverse(result.isReadDiverse);
      setIsReadComenzi(result.isReadComenzi);
      setIsReadIscir(result.isReadIscir);
      setIsReadOferte(result.isReadOferte);

      setAdministrativ12(result.administrativ12);
      setAdministrativ34(result.administrativ34);

      setOferte34(result.oferte34);
      setOferte7(result.oferte7);

      setNotificari12(result.notificari12);
      setNotificari34(result.notificari34);

      setTehnicianPontaje(result.pontat);
      setDispecerPontajeNr(result.pontajeNevalidate);

      setInterventii34(result.interventie34);
      setInterventii56(result.interventie56);
      setInterventii7(result.interventie7);

      setAscensoare12(result.ascensoare12);
      setAscensoare34(result.ascensoare34);
      setAscensoare56(result.ascensoare56);
      setAscensoare7(result.ascensoare7);

      setRevizii34(result.revizii3);
      setRevizii56(result.revizii56);
      setRevizii7(result.revizii7);

      setComenzi12(result.comenzi12);
      setComenzi34(result.comenzi34);
      setComenzi56(result.comenzi56);
      setComenzi7(result.comenzi7);

      setIscir12(result.iscir2);
      setIscir34(result.iscir34);
      setIscir56(result.iscir56);
      setIscir7(result.iscir7);

      setInterfoane12(result.interfoane12);
      setInterfoane34(result.interfoane34);
      setInterfoane56(result.interfoane56);
      setInterfoane7(result.interfoane7);

      setPsi2(result.psi2);
      setPsi34(result.psi34);
      setPsi56(result.psi56);
      setPsi7(result.psi7);

      setAltele12(result.altele12);
      setAltele34(result.altele34);
      setAltele56(result.altele56);
      setAltele7(result.altele7);

      setSediuMasini12(result.sediu12);
      setSediuMasini34(result.sediu34);
      setSediuMasini56(result.sediu56);
      setSediuMasini7(result.sediu7);

      setDiverse12(result.diverse12);
      setDiverse34(result.diverse34);
      setDiverse56(result.diverse56);
      setDiverse7(result.diverse7);
    } catch (err) {
      if (navigator && navigator.onLine) {
        setServerError(true);
      }
    }
  };

  useEffect(() => {
    const id = startRefreshTimer();
    return () => {
      clearInterval(id);
    };
  }, [refresh]);

  const startRefreshTimer = () => {
    return setInterval(() => {
      setRefresh(!refresh);
    }, refreshTimer);
  };

  useEffect(() => {
    if(user)
    getNumbers();
    checkInternet();
  }, [triggerNumere, refresh]);

  const closeModal = () => {
    setShowModal(false);
  };

  const startRefreshNewMediaTimer = () => {
    return setInterval(() => {
      setRefreshNewMedia(!refreshNewMedia);
    }, refreshNewMediaTimer);
  };

  useEffect(() => {
    if (user) {
      getNewMedia();
    }
    const id = startRefreshNewMediaTimer();
    return () => {
      clearInterval(id);
    }

  }, [refreshNewMedia, refreshNewMediaContext]);


  const rightClickLeftDashboard = (e, locationType) => {
    e.preventDefault();
    //sediu si diverse nu au interventii
    if (
      locationType !== "sediu" &&
      locationType !== "diverse" &&
      locationType !== "administrativ" &&
      locationType !== "notificari" &&
      locationType !== "oferte"
    ) {
      setCurrenctLocation(locationType);
      setShowModal(true);
    }
  };

  const getLucrareModalHeader = () => {
    switch (currentLocation) {
      case "ascensoare":
        return "Interventie Ascensoare";
      case "interfoane":
        return "Interventie Interfoane";
      case "psi":
        return "Interventie PSI";
      case "altele":
        return "Interventie Altele";
      case "sediu":
        return "Interventie Sediu/Masini";
      default:
        return "Interventie";
    }
  };

  useEffect(() => {
    setCurrentDomeniu(domeniu);
  }, [domeniu]);

  return (
    <>{user && (<Col md={2} className="col_leftDashboard_wrapper">
      <div className="leftDashboard_wrapper">
        <ModalComponent
          show={showModal}
          close={closeModal}
          headingText={getLucrareModalHeader()}
          body={
            <Lucrare
              closeModal={closeModal}
              locationType={currentLocation}
              isInterventie={true}
              refreshLucrari={refreshInterventii}
            />
          }
        />
        {isLogicsApp() &&
          <div className="leftDashboard_logo_wrapper">
            <Image className="leftDashboard_logo_sageti" src={logo} />
            <Image className="leftDashboard_logo" src={logo_text} />
            <Image onClick={() => {
              history.push("/newMediaViewer");
            }} className="new_media" src={isNewMedia !== true ? new_media_black : new_media_red}>
            </Image> 
          </div>}
        {isTehnicStudioApp() &&
        <div className="leftDashboard_logo_wrapper" onClick={
          () => {
            history.push("/about", {
              applicationName: "TehnicStudio"
            });
          }
        }>
          <Image className="leftDashboard_logo" src={logo_tehnicstudio} />
        </div>}
        {isPixiaConturApp() &&
        <div className="leftDashboard_logo_wrapper" onClick={
          () => {
            history.push("/about", {
              applicationName: "PixiaContur"
            });
          }
        }>
          <Image className="leftDashboard_logo" src={logo_pixia} />
        </div>}

        <div>
          <div className={`leftDashboard_item_fisa_zilnica`}>
            {isAdmin() || isDispecer() ? (
              <div
                className={`leftDashboard_item_administrativ ${currentDomeniu === "administrativ"
                  ? "leftDashboard_item_selected"
                  : ""
                  }`}
                onClick={() => {
                  setAdminnistrativProgramate(false);
                  history.push("/administrativ");
                  setCurrentDomeniu("administrativ");
                }}
              >
                <div
                  className={
                    !isReadAdministrativ ? (blinkAdminProg ? "admin_progr_blink_not_read" : "textDomeniuNotRead") : (blinkAdminProg ? "admin_progr_blink_read" : "textDomeniuRead")
                  }
                >
                  Admin
                </div>
                <div className="left_dashboard_number_wrapper">
                  <div
                    className={
                      administrativ12 !== 0
                        ? "nr_lucrari_faza_1_2"
                        : "nr_lucrari_not_visible"
                    }
                  >
                    {administrativ12 !== 0 ? administrativ12 : ""}
                  </div>
                  <div
                    className={
                      administrativ34 !== 0
                        ? blinkAdministrativ
                          ? "nr_lucrari_faza_3_4_blink"
                          : "nr_lucrari_faza_3_4"
                        : "nr_lucrari_not_visible"
                    }
                  >
                    {administrativ34 !== 0 ? administrativ34 : ""}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`leftDashboard_item_administrativ ${currentDomeniu === "notificari"
                  ? "leftDashboard_item_selected"
                  : ""
                  }`}
                onClick={() => {
                  history.push("/notificari");
                  setCurrentDomeniu("notificari");
                }}
              >
                <div
                  className={
                    isReadNotificari ? "textDomeniuRead" : "textDomeniuNotRead"
                  }
                >
                  Note
                </div>
                <div className="left_dashboard_number_wrapper">
                  <div
                    className={
                      notificari12 !== 0
                        ? "nr_lucrari_faza_1_2"
                        : "nr_lucrari_not_visible"
                    }
                  >
                    {notificari12 !== 0 ? notificari12 : ""}
                  </div>
                  <div
                    className={
                      notificari34 !== 0
                        ? blinkNotificari
                          ? "nr_lucrari_faza_3_4_blink"
                          : "nr_lucrari_faza_3_4"
                        : "nr_lucrari_not_visible"
                    }
                  >
                    {notificari34 !== 0 ? notificari34 : ""}
                  </div>
                </div>
              </div>
            )}
            <div
              className={`leftDashboard_item_oferte ${currentDomeniu === "oferte" ? "leftDashboard_item_selected" : ""
                }`}
              onClick={() => {
                history.push("/oferte");
                setCurrentDomeniu("oferte");
              }}
            >
              <div
                className={
                  isReadOferte ? "textDomeniuRead" : "textDomeniuNotRead"
                }
              >
                Oferte
              </div>
              <div className="left_dashboard_number_wrapper">
                <div
                  className={
                    oferte34 !== 0
                      ? blinkOferte
                        ? "nr_lucrari_faza_3_4_blink"
                        : "nr_lucrari_faza_3_4"
                      : "nr_lucrari_not_visible"
                  }
                >
                  {oferte34 !== 0 ? oferte34 : ""}
                </div>

                <div
                  className={
                    oferte7 !== 0 ? "nr_lucrari_faza_7" : "nr_lucrari_not_visible"
                  }
                >
                  {oferte7 !== 0 ? oferte7 : ""}
                </div>
              </div>
            </div>
          </div>

          {isLogicsApp() && <div className={`leftDashboard_item_fisa_zilnica`}>
            <div
              onClick={() => {
                setCurrentUser(null);
                history.push("/pontaje");
                setCurrentDomeniu("pontaje");
              }}
              className={`leftDashboard_item_pontaj ${currentDomeniu === "pontaje" ? "leftDashboard_item_selected" : ""
                }`}
            >
              <div className={"textDomeniuRead"}>Pontaj</div>
              <div className="left_dashboard_number_wrapper">
                {isAdmin() || isDispecer() ? (
                  <div
                    className={
                      dispecerPontajeNr !== 0
                        ? "nr_lucrari_faza_7"
                        : "nr_lucrari_not_visible"
                    }
                  >
                    {dispecerPontajeNr !== 0 ? dispecerPontajeNr : ""}
                  </div>
                ) : (
                  <div
                    className={
                      tehnicianPontaje !== 0
                        ? "nr_lucrari_faza_3_4"
                        : "nr_lucrari_not_visible"
                    }
                  >
                    {tehnicianPontaje !== 0 ? tehnicianPontaje : ""}
                  </div>
                )}
              </div>
            </div>

            <div
              className={`leftDashboard_item_fisaZ ${currentDomeniu === "fisaZilnica"
                ? "leftDashboard_item_selected"
                : ""
                }`}
              onClick={() => {
                setCurrentUser(null);
                history.push("/fisaZilnica");
                setCurrentDomeniu("fisaZilnica");
              }}
            >
              <div className={"textDomeniuRead"}>Fisa Zilnica</div>
            </div>
          </div>}
          {isLogicsApp() && <div
            className={`leftDashboard_item_interventii ${currentDomeniu === "interventii"
              ? "leftDashboard_item_selected"
              : ""
              }`}
            onClick={() => {
              setCurrentDomeniu("interventii");
              history.push("/interventii");
            }}
          >
            <div
              className={
                isReadInterventii ? "textDomeniuRead" : "textDomeniuNotRead"
              }
            >
              Interventii
            </div>
            <div className="left_dashboard_number_wrapper">
              <div
                className={
                  interventii34 !== 0
                    ? blinkInterventi
                      ? "nr_lucrari_faza_3_4_blink"
                      : "nr_lucrari_faza_3_4"
                    : "nr_lucrari_not_visible"
                }
              >
                {interventii34 !== 0 ? interventii34 : ""}
              </div>
              <div
                className={
                  interventii56 !== 0
                    ? interventiiBlink5
                      ? "nr_lucrari_faza_5_6_blink"
                      : "nr_lucrari_faza_5_6"
                    : "nr_lucrari_not_visible"
                }
              >
                {interventii56 !== 0 ? interventii56 : ""}
              </div>
              <div
                className={
                  interventii7 !== 0
                    ? "nr_lucrari_faza_7"
                    : "nr_lucrari_not_visible"
                }
              >
                {interventii7 !== 0 ? interventii7 : ""}
              </div>
            </div>
          </div>}
          {isTehnicStudioApp() && <div
            className={`leftDashboard_item_ascensoare ${currentDomeniu === "smart_signage" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/smartSignage");
              setCurrentDomeniu("smart_signage");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "smart_signage")}
          >
            <div
              className={"textDomeniuRead"}
            >
              Smart Signage
            </div>
          </div>}
          {isTehnicStudioApp() && <div
            className={`leftDashboard_item_ascensoare ${currentDomeniu === "software" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/software");
              setCurrentDomeniu("software");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "software")}
          >
            <div
              className={"textDomeniuRead"}
            >
              Software
            </div>
          </div>}
          {isTehnicStudioApp() && <div
            className={`leftDashboard_item_ascensoare ${currentDomeniu === "controllers" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/controllers");
              setCurrentDomeniu("controllers");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "controllers")}
          >
            <div
              className={"textDomeniuRead"}
            >
              Controllers
            </div>
          </div>}
          {isPixiaConturApp() && <div
            className={`leftDashboard_item_ascensoare ${currentDomeniu === "personalizari_textile" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/personalizariTextile");
              setCurrentDomeniu("personalizari_textile");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "personalizari_textile")}
          >
            <div
              className={"textDomeniuRead"}
            >
              Personalizari textile
            </div>
          </div>}
          {isPixiaConturApp() && <div
            className={`leftDashboard_item_ascensoare ${currentDomeniu === "personalizari_obiecte" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/personalizariObiecte");
              setCurrentDomeniu("personalizari_obiecte");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "personalizari_obiecte")}
          >
            <div
              className={"textDomeniuRead"}
            >
              Personalizari obiecte
            </div>
          </div>}
          {isPixiaConturApp() && <div
            className={`leftDashboard_item_ascensoare ${currentDomeniu === "tiparituri" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/tiparituri");
              setCurrentDomeniu("tiparituri");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "tiparituri")}
          >
            <div
              className={"textDomeniuRead"}
            >
              Tiparituri
            </div>
          </div>}
          {isLogicsApp() && <div
            className={`leftDashboard_item_ascensoare ${currentDomeniu === "ascensoare" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              setCurrentDomeniu("ascensoare");
              history.push("/ascensoare");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "ascensoare")}
          >
            <div
              className={
                isReadAscensoare ? "textDomeniuRead" : "textDomeniuNotRead"
              }
            >
              Ascensoare
            </div>
            <div className="left_dashboard_number_wrapper">
              <div
                className={
                  ascensoare12 !== 0
                    ? "nr_lucrari_faza_1_2"
                    : "nr_lucrari_not_visible"
                }
              >
                {ascensoare12 !== 0 ? ascensoare12 : ""}
              </div>
              <div
                className={
                  ascensoare34 !== 0
                    ? blinkAscensoare
                      ? "nr_lucrari_faza_3_4_blink"
                      : "nr_lucrari_faza_3_4"
                    : "nr_lucrari_not_visible"
                }
              >
                {ascensoare34 !== 0 ? ascensoare34 : ""}
              </div>
              <div
                className={
                  ascensoare56 !== 0
                    ? ascensoareBlink5
                      ? "nr_lucrari_faza_5_6_blink"
                      : "nr_lucrari_faza_5_6"
                    : "nr_lucrari_not_visible"
                }
              >
                {ascensoare56 !== 0 ? ascensoare56 : ""}
              </div>
              <div
                className={
                  ascensoare7 !== 0
                    ? "nr_lucrari_faza_7"
                    : "nr_lucrari_not_visible"
                }
              >
                {ascensoare7 !== 0 ? ascensoare7 : ""}
              </div>
            </div>
          </div>}
          {isLogicsApp() && <div
            className={`leftDashboard_item_interfoane ${currentDomeniu === "interfoane" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/interfoane");
              setCurrentDomeniu("interfoane");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "interfoane")}
          >
            <div
              className={
                isReadInterfoane ? "textDomeniuRead" : "textDomeniuNotRead"
              }
            >
              Interfoane
            </div>
            <div className="left_dashboard_number_wrapper">
              <div
                className={
                  interfoane12 !== 0
                    ? "nr_lucrari_faza_1_2"
                    : "nr_lucrari_not_visible"
                }
              >
                {interfoane12 !== 0 ? interfoane12 : ""}
              </div>
              <div
                className={
                  interfoane34 !== 0
                    ? blinkInterfoane
                      ? "nr_lucrari_faza_3_4_blink"
                      : "nr_lucrari_faza_3_4"
                    : "nr_lucrari_not_visible"
                }
              >
                {interfoane34 !== 0 ? interfoane34 : ""}
              </div>

              <div
                className={
                  interfoane56 !== 0
                    ? interfoaneBlink5
                      ? "nr_lucrari_faza_5_6_blink"
                      : "nr_lucrari_faza_5_6"
                    : "nr_lucrari_not_visible"
                }
              >
                {interfoane56 !== 0 ? interfoane56 : ""}
              </div>
              <div
                className={
                  interfoane7 !== 0
                    ? "nr_lucrari_faza_7"
                    : "nr_lucrari_not_visible"
                }
              >
                {interfoane7 !== 0 ? interfoane7 : ""}
              </div>
            </div>
          </div>}
         {isLogicsApp() && <div
            className={`leftDashboard_item_psi ${currentDomeniu === "psi" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              setCurrentMonth(null);
              history.push("/psi");
              setCurrentDomeniu("psi");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "psi")}
          >
            <div className={isReadPsi ? "textDomeniuRead" : "textDomeniuNotRead"}>
              PSI
            </div>
            <div className="left_dashboard_number_wrapper">
              <div
                className={
                  psi2 !== 0 ? "nr_lucrari_faza_1_2" : "nr_lucrari_not_visible"
                }
              >
                {psi2 !== 0 ? psi2 : ""}
              </div>
              <div
                className={
                  psi34 !== 0
                    ? blinkPsi
                      ? "nr_lucrari_faza_3_4_blink"
                      : "nr_lucrari_faza_3_4"
                    : "nr_lucrari_not_visible"
                }
              >
                {psi34 !== 0 ? psi34 : ""}
              </div>
              <div
                className={
                  psi56 !== 0
                    ? psiBlink5
                      ? "nr_lucrari_faza_5_6_blink"
                      : "nr_lucrari_faza_5_6"
                    : "nr_lucrari_not_visible"
                }
              >
                {psi56 !== 0 ? psi56 : ""}
              </div>
              <div
                className={
                  psi7 !== 0 ? "nr_lucrari_faza_7" : "nr_lucrari_not_visible"
                }
              >
                {psi7 !== 0 ? psi7 : ""}
              </div>
            </div>
          </div>}
          <div
            className={`leftDashboard_item_altele ${currentDomeniu === "altele" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/altele");
              setCurrentDomeniu("altele");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "altele")}
          >
            <div
              className={isReadAltele ? "textDomeniuRead" : "textDomeniuNotRead"}
            >
              Altele
            </div>
            <div className="left_dashboard_number_wrapper">
              <div
                className={
                  altele12 !== 0
                    ? "nr_lucrari_faza_1_2"
                    : "nr_lucrari_not_visible"
                }
              >
                {altele12 !== 0 ? altele12 : ""}
              </div>
              <div
                className={
                  altele34 !== 0
                    ? blinkAltele
                      ? "nr_lucrari_faza_3_4_blink"
                      : "nr_lucrari_faza_3_4"
                    : "nr_lucrari_not_visible"
                }
              >
                {altele34 !== 0 ? altele34 : ""}
              </div>
              <div
                className={
                  altele56 !== 0
                    ? alteleBlink5
                      ? "nr_lucrari_faza_5_6_blink"
                      : "nr_lucrari_faza_5_6"
                    : "nr_lucrari_not_visible"
                }
              >
                {altele56 !== 0 ? altele56 : ""}
              </div>
              <div
                className={
                  altele7 !== 0 ? "nr_lucrari_faza_7" : "nr_lucrari_not_visible"
                }
              >
                {altele7 !== 0 ? altele7 : ""}
              </div>
            </div>
          </div>
          {isLogicsApp() && <div
            className={`leftDashboard_item_revizii ${currentDomeniu === "revizii" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              setReviziiFaza1(false);
              history.push("/revizii");
              setCurrentDomeniu("revizii");
            }}
          >
            <div className="ml-2">Revizii</div>
            <div className="left_dashboard_number_wrapper">
              <div
                className={
                  revizii34 !== 0
                    ? blinkRevizii
                      ? "nr_lucrari_faza_3_4_blink"
                      : "nr_lucrari_faza_3_4"
                    : "nr_lucrari_not_visible"
                }
              >
                {revizii34 !== 0 ? revizii34 : ""}
              </div>
              <div
                className={
                  revizii56 !== 0
                    ? reviziiBlink5
                      ? "nr_lucrari_faza_5_6_blink"
                      : "nr_lucrari_faza_5_6"
                    : "nr_lucrari_not_visible"
                }
              >
                {revizii56 !== 0 ? revizii56 : ""}
              </div>
              <div
                className={
                  revizii7 !== 0 ? "nr_lucrari_faza_7" : "nr_lucrari_not_visible"
                }
              >
                {revizii7 !== 0 ? revizii7 : ""}
              </div>
            </div>
          </div>}
          {isLogicsApp() && <div
            className={`leftDashboard_item_iscir ${currentDomeniu === "iscir" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              setIscirFaza1(false);
              history.push("/iscir");
              setCurrentDomeniu("iscir");
            }}
          >
            <div
              className={isReadIscir ? "textDomeniuRead" : "textDomeniuNotRead"}
            >
              ISCIR
            </div>
            <div className="left_dashboard_number_wrapper">
              <div
                className={
                  iscir12 !== 0 ? "nr_lucrari_faza_1_2" : "nr_lucrari_not_visible"
                }
              >
                {iscir12 !== 0 ? iscir12 : ""}
              </div>
              <div
                className={
                  iscir34 !== 0
                    ? blinkIscir
                      ? "nr_lucrari_faza_3_4_blink"
                      : "nr_lucrari_faza_3_4"
                    : "nr_lucrari_not_visible"
                }
              >
                {iscir34 !== 0 ? iscir34 : ""}
              </div>
              <div
                className={
                  iscir56 !== 0
                    ? iscirBlink5
                      ? "nr_lucrari_faza_5_6_blink"
                      : "nr_lucrari_faza_5_6"
                    : "nr_lucrari_not_visible"
                }
              >
                {iscir56 !== 0 ? iscir56 : ""}
              </div>
              <div
                className={
                  iscir7 !== 0 ? "nr_lucrari_faza_7" : "nr_lucrari_not_visible"
                }
              >
                {iscir7 !== 0 ? iscir7 : ""}
              </div>
            </div>
          </div>}
          <div
            className={`leftDashboard_item_sediuMasini ${currentDomeniu === "sediu" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/sediuMasini");
              setCurrentDomeniu("sediuMasini");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "sediu")}
          >
            <div
              className={isReadSediu ? "textDomeniuRead" : "textDomeniuNotRead"}
            >
              Sediu/Masini
            </div>
            <div className="left_dashboard_number_wrapper">
              <div
                className={
                  sediuMasini12 !== 0
                    ? "nr_lucrari_faza_1_2"
                    : "nr_lucrari_not_visible"
                }
              >
                {sediuMasini12 !== 0 ? sediuMasini12 : ""}
              </div>
              <div
                className={
                  sediuMasini34 !== 0
                    ? blinkSediu
                      ? "nr_lucrari_faza_3_4_blink"
                      : "nr_lucrari_faza_3_4"
                    : "nr_lucrari_not_visible"
                }
              >
                {sediuMasini34 !== 0 ? sediuMasini34 : ""}
              </div>
              <div
                className={
                  sediuMasini56 !== 0
                    ? sediuBlink5
                      ? "nr_lucrari_faza_5_6_blink"
                      : "nr_lucrari_faza_5_6"
                    : "nr_lucrari_not_visible"
                }
              >
                {sediuMasini56 !== 0 ? sediuMasini56 : ""}
              </div>
              <div
                className={
                  sediuMasini7 !== 0
                    ? "nr_lucrari_faza_7"
                    : "nr_lucrari_not_visible"
                }
              >
                {sediuMasini7 !== 0 ? sediuMasini7 : ""}
              </div>
            </div>
          </div>
          <div
            className={`leftDashboard_item_diverse ${currentDomeniu === "diverse" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/diverse");
              setCurrentDomeniu("diverse");
            }}
            onContextMenu={(e) => rightClickLeftDashboard(e, "diverse")}
          >
            <div
              className={isReadDiverse ? "textDomeniuRead" : "textDomeniuNotRead"}
            >
              Diverse
            </div>
            <div className="left_dashboard_number_wrapper">
              <div
                className={
                  diverse12 !== 0
                    ? "nr_lucrari_faza_1_2"
                    : "nr_lucrari_not_visible"
                }
              >
                {diverse12 !== 0 ? diverse12 : ""}
              </div>
              <div
                className={
                  diverse34 !== 0
                    ? blinkDiverse
                      ? "nr_lucrari_faza_3_4_blink"
                      : "nr_lucrari_faza_3_4"
                    : "nr_lucrari_not_visible"
                }
              >
                {diverse34 !== 0 ? diverse34 : ""}
              </div>
              <div
                className={
                  diverse56 !== 0
                    ? diverseBlink5
                      ? "nr_lucrari_faza_5_6_blink"
                      : "nr_lucrari_faza_5_6"
                    : "nr_lucrari_not_visible"
                }
              >
                {diverse56 !== 0 ? diverse56 : ""}
              </div>
              <div
                className={
                  diverse7 !== 0 ? "nr_lucrari_faza_7" : "nr_lucrari_not_visible"
                }
              >
                {diverse7 !== 0 ? diverse7 : ""}
              </div>
            </div>
          </div>
          <div
            className={`leftDashboard_item_comenzi ${currentDomeniu === "comenzi" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/comenzi");
              setCurrentDomeniu("comenzi");
            }}
          >
            <div
              className={isReadComenzi ? "textDomeniuRead" : "textDomeniuNotRead"}
            >
              Comenzi
            </div>
            <div className="left_dashboard_number_wrapper">
              <div
                className={
                  comenzi12 !== 0
                    ? "nr_lucrari_faza_1_2"
                    : "nr_lucrari_not_visible"
                }
              >
                {comenzi12 !== 0 ? comenzi12 : ""}
              </div>
              <div
                className={
                  comenzi34 !== 0
                    ? blinkComenzi
                      ? "nr_lucrari_faza_3_4_blink"
                      : "nr_lucrari_faza_3_4"
                    : "nr_lucrari_not_visible"
                }
              >
                {comenzi34 !== 0 ? comenzi34 : ""}
              </div>
              <div
                className={
                  comenzi56 !== 0
                    ? comenziBlink5
                      ? "nr_lucrari_faza_5_6_blink"
                      : "nr_lucrari_faza_5_6"
                    : "nr_lucrari_not_visible"
                }
              >
                {comenzi56 !== 0 ? comenzi56 : ""}
              </div>
              <div
                className={
                  comenzi7 !== 0 ? "nr_lucrari_faza_7" : "nr_lucrari_not_visible"
                }
              >
                {comenzi7 !== 0 ? comenzi7 : ""}
              </div>
            </div>
          </div>
        </div>

        {isAdmin() && (
          <div
            className={`leftDashboard_item_setari ${currentDomeniu === "setari" ? "leftDashboard_item_selected" : ""
              }`}
            onClick={() => {
              history.push("/setari");
              setCurrentDomeniu("setari");
            }}
          >
            <div className={"textDomeniuRead"}>Setari</div>
          </div>
        )}

        <span onClick={handleLogout}>
          <i className="fa fa-power-off"></i>
        </span>
      </div>
    </Col>)
    }</>


  );
};

export default LeftDashboard;
