import React from "react";
import "../style/PontajItem.css";
import { showDateFormatFullYear, showDateFormatMonthYearRomanianName } from "../utils/Utils";
import { getUserName } from "../backend/RESTUtils";

const IstoricSalariiPontajTehnicianItem = ({ istoricSalariu }) => {
  return (
    <div
      className={`pontaj_istoric_item_wrapper pontajBackground`}
    >
      <div className={`istoric_salariu_item_month`}>
        <div className={`ml-2`} style={{color: "white"}}>
          {showDateFormatMonthYearRomanianName(new Date(istoricSalariu.date))}
        </div>
      </div>
      <div className={`istoric_salariu_item`} style={{ justifyContent: "space-around" }}>
        <div>
          CO:{" "}
          {istoricSalariu &&
            istoricSalariu.concediu !== 0 &&
            istoricSalariu.concediu}
        </div>
        <div>
          ZL:{" "}
          {istoricSalariu &&
            istoricSalariu.zileLibere !== 0 &&
            istoricSalariu.zileLibere}
        </div>
        <div>
          CM:{" "}
          {istoricSalariu &&
            istoricSalariu.medical !== 0 &&
            istoricSalariu.medical}
        </div>
        <div>
          AbN:{" "}
          <span>
            {istoricSalariu &&
              istoricSalariu.absenteNemotivate !== 0 &&
              istoricSalariu.absenteNemotivate}
          </span>
        </div>

      </div>
      <div className={`istoric_salariu_item`} style={{ justifyContent: "space-around" }}>
        <div>
          OL: {" "} {istoricSalariu && istoricSalariu.oreLucrate}[
          {istoricSalariu && istoricSalariu.oreLucrateParanteze}]
        </div>
      </div>
      <div className={`istoric_salariu_item`} style={{ justifyContent: "space-around" }}>
        <div>
          Inter: {" "}
          {istoricSalariu && istoricSalariu.interventii}
        </div>
        <div>Tel: {istoricSalariu && istoricSalariu.telefoane}</div>
      </div>
      <div className={`istoric_salariu_item`} style={{ justifyContent: "space-around" }}>
        <div>
          Avans: {" "}
          {new Intl.NumberFormat("ro", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          }).format(
            istoricSalariu && istoricSalariu.avans
              ? istoricSalariu && istoricSalariu.avans
              : 0
          )}
        </div>
        <div>
          Plata: {" "}
          {new Intl.NumberFormat("ro", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          }).format(
            istoricSalariu && istoricSalariu.plata
              ? istoricSalariu && istoricSalariu.plata
              : 0
          )}
        </div>
      </div>
      <div className={`istoric_salariu_item`} style={{ justifyContent: "space-around" }}>
        <div>
          Total: {" "}
          {new Intl.NumberFormat("ro", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          }).format(
            istoricSalariu && istoricSalariu.avans && istoricSalariu.plata
              ? (istoricSalariu.avans + istoricSalariu.plata)
              : 0
          )}
        </div>
      </div>
      {getUserName() === "hv" && <div className={`istoric_salariu_item`} style={{ justifyContent: "space-around" }}>
        <div>
          Lei/ora: {istoricSalariu.leiOra}
        </div>
        <div>
          Lei/interventie: {istoricSalariu.leiInterventie}
        </div>
        <div>
          Lei/telefon: {istoricSalariu.leiTelefon}
        </div>
      </div>}

    </div>
  );
};

export default IstoricSalariiPontajTehnicianItem;